<template>
  <article class="ps-block--store">
    <div class="ps-block__thumbnail bg--cover" data-background="/img/630.png">
      <img
        v-if="!shop.image_path"
        :src="`${image_url}storage/shop_image/default.png`"
        alt="Shop Image"
      />
      <!-- <img v-if="!shop.image_path" src="../../assets/User.png" alt="" /> -->
      <img v-else :src="`${shop.image_path}`" alt="Shop Image" />
    </div>
    <div class="ps-block__content">
      <div class="ps-block__author">
        <a class="ps-block__user" href="#"
          ><img src="../../assets/user.png" alt="User Image"
        /></a>
        <router-link :to="'/shop/' + shop.slug" class="ps-btn"
          >Visit Shop</router-link
        >
      </div>
      <h4>{{ shop.name }}</h4>
      <p>
        {{ shop.address.street }},{{ shop.address.city }},
        {{ shop.address.country }}
      </p>
      <ul class="ps-block__contact">
        <li>
          <i class="icon-envelope"></i>
          <a href="#">
            <span class="__cf_email__">{{ shop.user.email }}</span>
          </a>
        </li>
        <li><i class="icon-telephone"></i>{{ shop.user.phone_number }}</li>
      </ul>
      <div class="ps-block__inquiry">
        <a href="#"><i class="icon-question-circle"></i> inquiry</a>
      </div>
    </div>
  </article>
</template>
<script>
import global from "@/mixins/global";
export default {
  props: ["shop"],
  mixins: [global],
};
</script>
