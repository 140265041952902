<template>
  <div>
    <bread-crumb :name="'Search Results for ' + query"></bread-crumb>
    <section class="ps-store-list">
      <div class="ps-container">
        <div class="ps-section__header"></div>
        <div class="ps-section__wrapper">
          <div class="ps-section__left">
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Search</h3>
              <input class="form-control" type="text" placeholder="Search..." />
            </aside>
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Filter by Category</h3>
              <div class="form-group">
                <select class="form-control">
                  <option value="0" selected="selected">Filter by shop category</option>

                </select>
              </div>
            </aside>
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Filter by Location</h3>
              <div class="form-group">
                <input class="form-control" type="text" placeholder="Search by Town" />
              </div>
              <div class="form-group">
                <input class="form-control" type="text" placeholder="Search by City" />
              </div>
            </aside>
          </div>
          <div class="ps-section__right">
            <section class="ps-store-box" v-if="!loading">
              <div class="ps-section__header">
                <div class="ps-block__header">
                  <h3>Search results for {{ query }}</h3>
                  <hr />
                </div>
              </div>
              <div class="ps-section_content mb-5">
                <GoogleMap :shops="shops"></GoogleMap>
              </div>
              <div class="ps-section__content">
                <div class="row" v-if="shops.length>0">
                  <div v-for="shop in shops" :key="shop.id" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ShopCard :shop="shop"></ShopCard>
                  </div>
                </div>
                <!-- <div class="ps-pagination">
                  <ul class="pagination">
                    <li class="active">
                      <a href="#">1</a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li>
                      <a href="#">
                        Next Page
                        <i class="icon-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </section>

            <div v-else class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ShopCard from "@/components/Cards/ShopCard";
import BreadCrumb from "@/components/Navs/BreadCrumb.vue";
import GoogleMap from "@/components/GoogleMap.vue";
import axios from "axios";
import global from "@/mixins/global";
export default {
  components: { ShopCard, BreadCrumb, GoogleMap },
  data() {
    return {
      query: "Harare Chitungwiza",
      shops: [],
      loading: true,
    };
  },
  mixins: [global],
  mounted() {
    this.query = this.$route.params.query;
    this.search();
  },

  methods: {
    search() {
      if (this.$route.params.type === "location") {
        axios.get("search/shops/location?query=" + this.$route.params.query).then((response) => {
          this.shops = response.data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      }
      if (this.$route.params.type === "category") {
        axios.get("search/shops/category?query=" + this.$route.params.query).then((response) => {
          this.shops = response.data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      }
      if (this.$route.params.type === "shop") {
        axios.get("search/shops?query=" + this.$route.params.query).then((response) => {
          this.shops = response.data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      }
    },
  },
};
</script>